/* ELEMENTS: link styles */

a {
	position: relative;
	color: @darkBlueColor;
	font-weight: bold;
	cursor: pointer;
	text-decoration: underline;
	line-height: 14px;

	&:hover {
		text-decoration: none;
	}

	&.blue-link {
		color: @lightBlueColor;
	}
	&.new-blue-link {
		font-weight: normal;
		color: @newBlueColor;
	}
	&.light-link {
		color: #e9eef4 !important;
	}
	&.blue-gray-link {
		color: #707c8a;
		text-decoration: none;

		&:hover {
			color: @grayBlueColor;
		}
	}
	&.dark-yellow-link {
		color: @darkYellowColor;
		text-decoration: none;
		text-transform: uppercase;

		&:hover {
			color: @veryDarkYellowColor
		}
	}
	&.light-weight-link {
		font-weight: normal;
	}
	&.inactive {
		opacity: 0.3;
		cursor: default;
		pointer-events: none;
	}
}