/* CORE: fonts */

@font-face {
	font-family: 'ProximaNova';
	font-style: normal;
	font-weight: normal;
	src: url('@{fontsPath}/ProximaNova-RegularWeb.woff') format('woff');
}

@font-face {
	font-family: 'ProximaNova';
	font-style: italic;
	font-weight: normal;
	src: url('@{fontsPath}/ProximaNova-RegularItWeb.woff') format('woff');
}

@font-face {
	font-family: 'ProximaNova';
	font-style: normal;
	font-weight: 300;
	src: url('@{fontsPath}/ProximaNova-LightWeb.woff') format('woff');
}

@font-face {
	font-family: 'ProximaNova';
	font-style: normal;
	font-weight: bold;
	src: url('@{fontsPath}/ProximaNova-SemiboldWeb.woff') format('woff');
}

@font-face {
	font-family: 'ProximaNova';
	font-style: italic;
	font-weight: bold;
	src: url('@{fontsPath}/ProximaNova-SemiboldItWeb.woff') format('woff');
}
