// ELEMENTS: General-30 icons

[class^="icon-general-"],
[class*=" icon-general-"] {
	&.extra-icon:after,
	&:before {
		.ds-uxpinIconFont();
	}
}
[class^="ds-icon--"], // to handle ocon without label before button icon
[class*=" ds-icon--"], // to handle ocon without label before button icon
[class^="icon-general-"],
[class*=" icon-general-"],
[class^="icon-font-"],
[class*=" icon-font-"],
[class^="icon-custom-"],
[class*=" icon-custom-"] {
	&.only-icon-font {
		height: 14px;
		align-items: center;
		display: flex;
		justify-content: center;
		overflow: hidden;
		text-indent: -5000px;
		white-space: nowrap;
		box-sizing: border-box;
		color: transparent;
		font-size: 0 !important;

		&:before {
			display: block;
			width: 100%;
			margin: 0;
			position: relative;
			text-indent: 0;
			text-align: center;
		}
	}
}

[class^="ds-icon--"], // to handle ocon without label before button icon
[class*=" ds-icon--"], // to handle ocon without label before button icon
[class^="icon-general-"],
[class*=" icon-general-"],
[class^="icon-font-"],
[class*=" icon-font-"],
[class^="icon-custom-"],
[class*=" icon-custom-"] {
	&.dark-icon {
		&:before {
			color: fade(#000, 50%) !important;
		}
	}

	label& {
		margin-bottom: 0;
	}
}
