/* CORE: fonts */

@font-face {
	font-family: 'ProximaNova';
	src: url('@{fontsPath}/ProximaNova-RegularWeb.woff') format('woff');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('@{fontsPath}/ProximaNova-RegularItWeb.woff') format('woff');
	font-style: italic;
	font-weight: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('@{fontsPath}/ProximaNova-LightWeb.woff') format('woff');
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('@{fontsPath}/ProximaNova-SemiboldWeb.woff') format('woff');
	font-style: normal;
	font-weight: bold;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('@{fontsPath}/ProximaNova-SemiboldItWeb.woff') format('woff');
	font-style: italic;
	font-weight: bold;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('@{fontsPath}/ProximaNova-BoldWeb.woff') format('woff');
	font-style: normal;
	font-weight: 800;
}
