.tfa__error-tip, .tfa__success-tip {
	display: none;
	color: red;
	font-family: Soleil, sans-serif;
	font-size: 14px;
	margin: 10px 0;
  }

  .visible {
	display: block;
  }

  .tfa__verify-code {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100vh;
	background-color: #283842;
  }

  .tfa__logo {
	position: absolute;
	top: 20px;
	left: 20px;
	z-index: 10;
  }

  .tfa__flex-wrapper {
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	padding: 20px;
  }

  .tfa__form-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
  }

  .tfa__h1 {
	font-family: Soleil, sans-serif;
	font-size: 28px;
	color: #fff;
	font-weight: 500;
	text-transform: none;
	margin-bottom: 24px;
  }

  .tfa__page-description {
	font-family: Soleil, sans-serif;
	font-size: 16px;
	color: #acacac;
  }

  .tfa__trusted-label {
	margin: 20px 0;
  }

  .tfa__page-description--alt {
	font-size: 14px;
	margin-left: 4px;
  }

  .tfa__input-container {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
  }

  .tfa__input-label {
	font-family: Soleil, sans-serif;
	font-size: 14px;
	color: #fff;
	align-self: start;
  }

  .tfa__input-field {
	width: 100%;
	max-width: 360px;
	margin-top: 8px;
	height: 40px;
	padding: 0px 12px;
	border: 1px solid #e6e6e6;
	border-radius: 4px;
	background-color: #fff;
	font-family: Soleil, sans-serif;
	font-size: 14px;
	color: #121212;
  }

  .tfa__btn-flat {
	width: 100%;
	max-width: 360px;
	height: 48px;
	padding: 14px 1px 14px 0px;
	border: 2px solid #121212;
	border-radius: 4px;
	background-color: #ccccff;
	font-family: Soleil, sans-serif;
	font-size: 16px;
	color: #121212;
	text-align: center;
	font-weight: 600;
  }

  .tfa__btn-flat:hover {
	background-color: #f3f3f3;
  }

  .tfa__action-resend {
	margin-top: 40px;
  }

  .tfa__resend-link, .tfa__underlined-link {
	font-family: Soleil, sans-serif;
	font-size: 14px;
	color: #ccccff;
	font-weight: 500;
  }

  .tfa__footer-text {
	font-family: Soleil, sans-serif;
	font-size: 14px;
	color: #fff;
  }

  .tfa__additional-links {
	padding: 20px;
	background-color: #283842;
	text-align: center;
  }
