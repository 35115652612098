/* CORE: global styles */

.layout-settings(
	// font sizes
	@contentSize: @baseFontSize,
	@h1size: 14px,
	@h2size: 16px,
	@h3size: 14px,
	@h4size: 14px,
	@h5size: 12px,

	// line-heights
	@contentLineHeight: @baseLineHeight,
	@h1lineHeight: auto,
	@h2lineHeight: auto,
	@h3lineHeight: auto,
	@h4lineHeight: auto,
	@h5lineHeight: auto,

	// font-weights
	@h1weight: bold,
	@h2weight: bold,
	@h3weight: bold,
	@h4weight: bold,
	@h5weight: normal,

	// text-transform
	@h1transform: uppercase,
	@h2transform: uppercase,
	@h3transform: uppercase,
	@h4transform: none,
	@h5transform: none,

	// colors
	@h1color: inherit,
	@h2color: inherit,
	@h3color: #b3bcc6,
	@h4color: @darkBlueColor,
	@h5color: #c5ccd4,
	@windowBg: #fff,
	@contentColor: @baseFontColor,
	@selectionBg: @lightBlueColor,
	@selectionFont: #fff,

	// dimensions
	@typographyElementsSpace: 11px,
	@olLeftPadding: 22px,
	@minWidth: 960px,
	@minHeight: 580px,

	// fonts
	@defaultFontFamily: ~'"ProximaNova", sans-serif',

	//conditions
	@stretchWindow: false,
	@minWindowWidth: false,
	@minWindowHeight: false,
	@noListsBottomMargin: false,
	@noParagraphBottomMargin: false,
	@lockYScrollOnMinBreakpoint: false
) {
	html {
		height: 100%;
		position: relative;
		background: @windowBg;

		& when (@stretchWindow = true) {
			width: 100%;
			overflow: hidden;
		}
		& when (@stretchWindow = false) {
			overflow: auto;
		}
		& when (@minWindowWidth = true) {
			min-width: @minWidth;
		}
		& when (@minWindowHeight = true) {
			min-height: @minHeight;
		}
		& when (@lockYScrollOnMinBreakpoint = true) {
			@media screen and (max-width: 1151px) {
				overflow-y: hidden;
			}
		}
	}
	body {
		min-height: 100%;
		color: @contentColor;
		font-family: @defaultFontFamily;
		font-size: @contentSize;
		line-height: @contentLineHeight;

		& when (@stretchWindow = true) {
			width: 100%;
			height: 100%;
			position: relative;
			overflow: hidden;
		}
		& when (@stretchWindow = false) {
			overflow-x: hidden;
		}
	}
	a {
		cursor: pointer;
	}

	h1 {
		color: @h1color;
		font-size: @h1size;
		font-weight: @h1weight;
		text-transform: @h1transform;
		line-height: @h1lineHeight;
	}
	h2 {
		color: @h2color;
		font-size: @h2size;
		font-weight: @h2weight;
		text-transform: @h2transform;
		line-height: @h2lineHeight;
	}
	h3 {
		color: @h3color;
		font-size: @h3size;
		font-weight: @h3weight;
		text-transform: @h3transform;
		line-height: @h3lineHeight;
	}
	h4 {
		color: @h4color;
		font-size: @h4size;
		font-weight: @h4weight;
		text-transform: @h4transform;
		line-height: @h4lineHeight;
	}
	h5 {
		color: @h5color;
		font-size: @h5size;
		font-weight: @h5weight;
		text-transform: @h5transform;
		line-height: @h5lineHeight;
	}

	p,
	ul,
	ol,
	address {
		margin-bottom: @typographyElementsSpace;
	}
	& when (@noParagraphBottomMargin = true) {
		p {
			margin-bottom: 0;
		}
	}
	p {
		+ ul,
		+ ol {
			margin-top: -@typographyElementsSpace;
		}
	}
	ol {
		padding-left: @olLeftPadding;
	}
	ol,
	ul,
	nav {
		& & {
			margin-bottom: 0;
		}
	}
	ol {
		li {
			list-style: decimal;
		}
	}
	ul,
	ol {
		& when (@noListsBottomMargin = true) {
			margin-bottom: 0;
		}
	}

	address {
		font-style: italic;
	}

	td,
	th {
		font-family: @defaultFontFamily;
		font-size: @contentSize;
		line-height: @contentLineHeight;
	}

	::selection {
		background: @selectionBg;
		color: @selectionFont;
	}
}
