/* Core: icons */
.icon-pen() {
	width: 16px;
	height: 16px;
	.mainSprite(transparent, 0, 0);
	content: '';
}
.icon-eye() {
	width: 16px;
	height: 9px;
	.mainSprite(transparent, -20px, 0);
	content: '';
}
.icon-speech-bubble() {
	width: 16px;
	height: 14px;
	.mainSprite(transparent, -40px, 0);
	content: '';
}
.icon-clock() {
	width: 16px;
	height: 16px;
	.mainSprite(transparent, -60px, 0);
	content: '';
}
.icon-share() {
	width: 16px;
	height: 16px;
	.mainSprite(transparent, -80px, 0);
	content: '';
}
.icon-more() {
	width: 16px;
	height: 4px;
	.mainSprite(transparent, -100px, 0);
	content: '';
}
.icon-search() {
	width: 16px;
	height: 16px;
	.mainSprite(transparent, -120px, 0);
	content: '';
}
.icon-grid() {
	width: 15px;
	height: 16px;
	.mainSprite(transparent, -140px, 0);
	content: '';
}
.icon-list() {
	width: 16px;
	height: 16px;
	.mainSprite(transparent, -160px, 0);
	content: '';
}
.icon-panel-trigger() {
	width: 20px;
	height: 16px;
	.mainSprite(transparent, -220px, 0);
	content: '';
}
.icon-image() {
	width: 32px;
	height: 24px;
	.mainSprite(transparent, -180px, 0);
	content: '';
}
.icon-close() {
	width: 10px;
	height: 10px;
	.mainSprite(transparent, -240px, 0);
	content: '';
}

/* czerwone */
.icon-pen-red() {
	width: 16px;
	height: 16px;
	.mainSprite(transparent, 0, -20px);
	content: '';
}
.icon-eye-red() {
	width: 16px;
	height: 9px;
	.mainSprite(transparent, -20px, -20px);
	content: '';
}
.icon-speech-bubble-red() {
	width: 16px;
	height: 14px;
	.mainSprite(transparent, -40px, -20px);
	content: '';
}
.icon-clock-red() {
	width: 16px;
	height: 16px;
	.mainSprite(transparent, -60px, -20px);
	content: '';
}
.icon-share-red() {
	width: 16px;
	height: 16px;
	.mainSprite(transparent, -80px, -20px);
	content: '';
}
.icon-more-red() {
	width: 16px;
	height: 4px;
	.mainSprite(transparent, -100px, -20px);
	content: '';
}
.icon-grid-red() {
	width: 15px;
	height: 16px;
	.mainSprite(transparent, -140px, -20px);
	content: '';
}
.icon-list-red() {
	width: 16px;
	height: 16px;
	.mainSprite(transparent, -160px, -20px);
	content: '';
}
.icon-folder() {
	width: 52px;
	height: 38px;
	.mainSprite(transparent, 0px, -40px);
	content: '';
}
.icon-app() {
	width: 50px;
	height: 40px;
	.mainSprite(transparent, -60px, -40px);
	content: '';
}
.icon-document() {
	width: 34px;
	height: 40px;
	.mainSprite(transparent, -120px, -40px);
	content: '';
}
.icon-down-arrow(@color: @darkBlueColor) {
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid @color;
}
.icon-up-arrow(@color: @darkBlueColor) {
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-bottom: 4px solid @color;
}
.icon-star() {
	content: '\272D';
	color: #656565;
	font-size: 14px;
}
.icon-star-light() {
	content: '\272D';
	color: #e9eef4;
	font-size: 14px;
}
.icon-sitemap() {
	width: 9px;
	height: 14px;
	.appSprite(0, -40px);
	content: '';
}
.icon-undo() {
	width: 16px;
	height: 10px;
	.appSprite(-20px, -40px);
	content: '';
}
.icon-redo() {
	width: 16px;
	height: 10px;
	.appSprite(-40px, -40px);
	content: '';
}
.icon-group() {
	width: 16px;
	height: 16px;
	.appSprite(-60px, -40px);
	content: '';
}
.icon-arrange() {
	width: 16px;
	height: 13px;
	.appSprite(-80px, -40px);
	content: '';
}
.icon-align() {
	width: 16px;
	height: 12px;
	.appSprite(-100px, -40px);
	content: '';
}
.icon-link() {
	width: 16px;
	height: 14px;
	.appSprite(-120px, -40px);
	content: '';
}
.icon-locked() {
	width: 12px;
	height: 16px;
	.appSprite(-140px, -40px);
	content: '';
}
.icon-unlocked() {
	width: 16px;
	height: 16px;
	.appSprite(-160px, -40px);
	content: '';
}
.icon-image() {
	width: 16px;
	height: 12px;
	.appSprite(-180px, -40px);
	content: '';
}

.icon-font-adjust(@color: #ffffff, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e60e";
}
.icon-font-crop(@color: #ffffff, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e60b";
}
.icon-font-thunder(@color: #ffffff, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e603";
}
.icon-font-image(@color: #ffffff, @size: 15px) {
	color: @color;
	font-size: @size;
	content: "\e60f";
}
.icon-font-brightness(@color: #798196, @size: 14px) {
	color: @color;
	font-size: @size;
	text-shadow: -1px -1px 0 rgba(45,49,58,0.5);
	content: "\e600";
}
.icon-font-contrast(@color: #798196, @size: 11px) {
	color: @color;
	font-size: @size;
	text-shadow: -1px -1px 0 rgba(45,49,58,0.5);
	content: "\e60c";
}
.icon-font-saturation(@color: #798196, @size: 14px) {
	color: @color;
	font-size: @size;
	text-shadow: -1px -1px 0 rgba(45,49,58,0.5);
	content: "\e605";
}
.icon-font-vibrance(@color: #798196, @size: 12px) {
	color: @color;
	font-size: @size;
	text-shadow: -1px -1px 0 rgba(45,49,58,0.5);
	content: "\e601";
}
.icon-font-exposure(@color: #798196, @size: 12px) {
	color: @color;
	font-size: @size;
	text-shadow: -1px -1px 0 rgba(45,49,58,0.5);
	content: "\e608";
}
.icon-font-sharpness(@color: #798196, @size: 12px) {
	color: @color;
	font-size: @size;
	text-shadow: -1px -1px 0 rgba(45,49,58,0.5);
	content: "\e604";
}
.icon-font-upload(@color: #798196, @size: 14px) {
	color: @color;
	font-size: @size;
	text-shadow: -1px -1px 0 rgba(45,49,58,0.5);
	content: "\e602";
}
.icon-font-library(@color: #798196, @size: 11px) {
	color: @color;
	font-size: @size;
	text-shadow: -1px -1px 0 rgba(45,49,58,0.5);
	content: "\e606";
}
.icon-font-google-drive(@color: #798196, @size: 11px) {
	color: @color;
	font-size: @size;
	text-shadow: -1px -1px 0 rgba(45,49,58,0.5);
	content: "\e607";
}
.icon-font-dropbox(@color: #798196, @size: 11px) {
	color: @color;
	font-size: @size;
	text-shadow: -1px -1px 0 rgba(45,49,58,0.5);
	content: "\e609";
}
.icon-font-drag(@color: #ffffff, @size: 32px) {
	color: @color;
	font-size: @size;
	content: "\e60a";
}
.icon-font-arrows-width(@color: #b4b4b4, @size: 16px) {
	color: @color;
	font-size: @size;
	text-shadow: -1px -1px 0 rgba(45,49,58,0.5);
	content: "\e60d";
	transform: rotate(90deg);
}
.icon-font-arrows-height(@color: #b4b4b4, @size: 16px) {
	color: @color;
	font-size: @size;
	text-shadow: -1px -1px 0 rgba(45,49,58,0.5);
	content: "\e60d";
}
.icon-font-visible(@color: #ffffff, @size: 9px) {
	color: @color;
	font-size: @size;
	content: "\e611";
}
.icon-font-gear(@color: #8a8a8a, @size: 9px) {
 	color: @color;
 	font-size: @size;
 	content: "\e612";
}
 .icon-font-hidden(@color: #979aa1, @size: 9px) {
	color: @color;
	font-size: @size;
	content: "\e610";
}
.icon-font-twitter(@color: #515151, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e613";
}
.icon-font-facebook(@color: #515151, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e614";
}
.icon-font-google(@color: #515151, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e615";
}
.icon-font-ticker(@color: #7dae42, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e616";
}
.icon-font-map-pin(@color: #bdbdbd, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e62c";
}
.icon-font-heart(@color: #6b7286, @size: 10px) {
	color: @color;
	font-size: @size;
	content: '\e624';
}
.icon-font-broken-heart(@color: #6b7286, @size: 10px) {
	color: @color;
	font-size: @size;
	content: '\e625';
}
.icon-font-heart-outline(@color: #a2a2a2, @size: 8px) {
	color: @color;
	font-size: @size;
	content: '\e676';
	font-weight: bold;
}
.icon-font-rounded-close-outline(@color: #FFFFFF, @size: 10px) {
	color: @color;
	font-size: @size;
	content: '\e629';
}
.icon-font-back(@color: #656565, @size: 15px) {
	color: @color;
	font-size: @size;
	content: "\e617";
}
.icon-font-elements(@color: #ffffff, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e618";
}
.icon-font-smart-elements(@color: #ffffff, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e619";
}
.icon-font-search(@color: #ffffff, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e61a";
}
.icon-font-workers(@color: #ffffff, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e61c";
}
.icon-font-comments(@color: #ffffff, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e622";
}
.icon-font-help(@color: #ffffff, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e61d";
}
.icon-font-patterns(@color: #ffffff, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e61e";
}
.icon-font-note(@color: #ffffff, @size: 22px) {
	color: @color;
	font-size: @size;
	content: "\e61b";
}
.icon-font-arrow(@color: #999999, @size: 7px) {
	color: @color;
	font-size: @size;
	content: "\e61f";
}
.icon-font-close(@color: #d2d2d2, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e620";
}
.icon-font-rounded-close(@color: #000000, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e627";
}
.icon-font-info(@color: #ffffff, @size: 24px) {
	color: @color;
	font-size: @size;
	content: "\e621";
}
.icon-font-sitemap(@color: #ffffff, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e623";
}
.icon-font-cloud(@color: @lightBlueColor, @size: 10px) {
	color: @color;
	font-size: @size;
	content: "\e626";
}
.icon-font-add(@color: @lightBlueColor, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e62a";
}
.icon-font-rounded-add(@color: #515151, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e627";
	transform: rotate(45deg);
}
.icon-font-empty-window(@color: #ffffff, @size: 31px) {
	color: @color;
	font-size: @size;
	content: "\e628";
}
.icon-font-warning(@color: #ffffff, @size: 25px) {
	color: @color;
	font-size: @size;
	content: '\e62b';
}
.icon-font-edit-text(@color: #000000, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e62e";
}
.icon-font-edit-object(@color: #000000, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e62f";
}
.icon-font-down-arrow(@color: #ababab, @size: 3px) {
	color: @color;
	font-size: @size;
	content: '\e62d';
}
.icon-font-right-arrow(@color: #ababab, @size: 3px) {
	color: @color;
	font-size: @size;
	content: '\e62d';
	transform: rotate(270deg);
}
.icon-font-viewfinder(@color: #444444, @size: 12px) {
	color: @color;
	font-size: @size;
	content: "\e631";
}
.icon-font-trash(@color: #ffffff, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e630";
}

.icon-font-duplicate(@color: #ffffff, @size: 12px) {
	color: @color;
	font-size: @size;
	content: "\e64c";
}
.icon-font-locked(@color: #ffffff, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e64d";
}
.icon-font-unlocked(@color: #ffffff, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e64e";
}
.icon-font-ellipsis(@color: #768197, @size: 4px) {
	color: @color;
	font-size: @size;
	content: "\e64f";
}
.icon-font-folder(@color: #768197, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e656";
}
.icon-font-element(@color: #768197, @size: 13px) {
	color: @color;
	font-size: @size;
	content: "\e659";
}
.icon-font-text-element(@color: #768197, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e657";
}
.icon-font-image-element(@color: #768197, @size: 13px) {
	color: @color;
	font-size: @size;
	content: "\e658";
}
.icon-font-video(@color: #656565, @size: 10px) {
	color: @color;
	font-size: @size;
	content: "\e632";
}
.icon-font-rotation(@color: #bababa, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e633";
}
.icon-font-circle-info(@color: #FFFFFF, @size: 15px) {
	color: @color;
	font-size: @size;
	content: "\e636";
}
.icon-font-shapes(@color: #FFFFFF, @size: 17px) {
	color: @color;
	font-size: @size;
	content: "\e63c";
}
.icon-font-control(@color: #FFFFFF, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e637";
}
.icon-font-pattern(@color: #FFFFFF, @size: 15px) {
	color: @color;
	font-size: @size;
	content: "\e63b";
}
.icon-font-border(@color: #FFFFFF, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e638";
}
.icon-font-star(@color: #FFFFFF, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e669";
}
.icon-font-a-letter(@color: #FFFFFF, @size: 13px) {
	color: @color;
	font-size: @size;
	content: "\e63a";
}
.icon-font-font-size(@color: #b4b4b4, @size: 9px) {
	color: @color;
	font-size: @size;
	content: "\e63d";
}
.icon-font-font-bold(@color: #7a8294, @size: 12px) {
	color: @color;
	font-size: @size;
	content: "\e63e";
}
.icon-font-font-italic(@color: #7a8294, @size: 10px) {
	color: @color;
	font-size: @size;
	content: "\e63f";
}
.icon-font-text-underline(@color: #7a8294, @size: 10px) {
	color: @color;
	font-size: @size;
	content: "\e640";
}
.icon-font-text-strike(@color: #7a8294, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e641";
}
.icon-font-align-left(@color: #7a8294, @size: 10px) {
	color: @color;
	font-size: @size;
	content: "\e642";
}
.icon-font-align-center(@color: #7a8294, @size: 10px) {
	color: @color;
	font-size: @size;
	content: "\e643";
}
.icon-font-align-right(@color: #7a8294, @size: 10px) {
	color: @color;
	font-size: @size;
	content: "\e644";
}
.icon-font-align-justify(@color: #7a8294, @size: 10px) {
	color: @color;
	font-size: @size;
	content: "\e645";
}
.icon-font-line-height(@color: #b4b4b4, @size: 10px) {
	color: @color;
	font-size: @size;
	content: "\e646";
}
.icon-font-list-ordered(@color: #7a8294, @size: 7px) {
	color: @color;
	font-size: @size;
	content: "\e647";
}
.icon-font-list-unordered(@color: #7a8294, @size: 8px) {
	color: @color;
	font-size: @size;
	content: "\e648";
}
.icon-font-line-dashed(@color: #7a8294, @size: 2px) {
	color: @color;
	font-size: @size;
	content: "\e649";
}
.icon-font-line-dotted(@color: #7a8294, @size: 2px) {
	color: @color;
	font-size: @size;
	content: "\e64a";
}
.icon-font-line-solid(@color: #7a8294, @size: 2px) {
	color: @color;
	font-size: @size;
	content: "\e64b";
}
.icon-font-link(@color: #ffffff, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e634";
}
.icon-font-unlink(@color: #979aa1, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e635";
}
.icon-font-solid-star(@color: #7a8294, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e650";
	font-weight: bold;
}
.icon-font-solid-triangle(@color: #7a8294, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e651";
	font-weight: bold;
}
.icon-font-solid-rhombus(@color: #7a8294, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e653";
	font-weight: bold;
}
.icon-font-solid-parallel(@color: #7a8294, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e652";
	font-weight: bold;
}
.icon-font-solid-triangle2(@color: #7a8294, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e654";
	font-weight: bold;
}
.icon-font-edit-group(@color: #ffffff, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e65a";
}
.icon-font-states(@color: #ffffff, @size: 12px) {
	color: @color;
	font-size: @size;
	content: "\e695";
}
.icon-font-states-edit(@color: #ffffff, @size: 18px) {
	color: @color;
	font-size: @size;
	content: "\e699";
}
.icon-font-ellipsis-horizontal(@color: #768197, @size: 4px) {
	color: @color;
	font-size: @size;
	content: "\e64f";
}
.icon-font-ellipsis-vertical(@color: #768197, @size: 4px) {
	color: @color;
	font-size: @size;
	content: "\e64f";
	transform: rotate(90deg);
}
.icon-font-font-color-drop(@color: #ffffff, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e65d";
}
.icon-font-border-color-drop(@color: #ffffff, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e65e";
}
.icon-font-drop(@color: #ffffff, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e65c";
}
.icon-font-star-color-drop(@color: #ffffff, @size: 17px) {
	color: @color;
	font-size: @size;
	content: "\e66a";
}
.icon-font-curved-arrow-left(@color: #FFFFFF, @size: 22px) {
	color: @color;
	font-size: @size;
	content: "\e65b";
}
.icon-font-tooltip-top(@color: #7a8294, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e663";
}
.icon-font-tooltip-bottom(@color: #7a8294, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e663";
	transform: rotate(180deg);
}
.icon-font-tooltip-left(@color: #7a8294, @size: 10px) {
	color: @color;
	font-size: @size;
	content: "\e664";
}
.icon-font-tooltip-right(@color: #7a8294, @size: 10px) {
	color: @color;
	font-size: @size;
	content: "\e664";
	transform: rotate(180deg);
}
.icon-font-duplicate(@color: #768197, @size: 12px) {
	color: @color;
	font-size: @size;
	content: "\e64c";
}
.icon-font-iterations(@color: #ffffff, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e660";
}
.icon-font-play(@color: #ffffff, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e661";
}
.icon-font-layers(@color: #FFFFFF, @size: 15px) {
	color: @color;
	font-size: @size;
	content: "\e65f";
}
.icon-font-share(@color: #ffffff, @size: 13px) {
	color: @color;
	font-size: @size;
	content: "\e662";
}
.icon-font-offline(@color: #616161, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e665";
}
.icon-font-online(@color: #616161, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e667";
}
.icon-font-rounded-check(@color: #ACACAC, @size: 12px) {
	color: @color;
	font-size: @size;
	content: "\e666";
}
.icon-font-mail(@color: #d7d7d7, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "@";
	font-weight: bold;
}
.icon-font-students-hat(@color: #dfdfdf, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e66b";
}
.icon-font-down-arrow-light(@color: #bebebe, @size: 8px) {
	color: @color;
	font-size: @size;
	content: "\e66f";
}
.icon-font-up-arrow-light(@color: #bebebe, @size: 8px) {
	color: @color;
	font-size: @size;
	content: "\e66f";
	transform: rotate(180deg);
}
.icon-font-grid(@color: #bbbbba, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e670";
}
.icon-font-list(@color: #bbbbba, @size: 11px) {
	color: @color;
	font-size: @size;
	content: "\e671";
}
.icon-font-user(@color: #bebebe, @size: 12px) {
	color: @color;
	font-size: @size;
	content: "\e668";
}
.icon-font-file(@color: #cfcfcf, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e672";
}
.icon-font-archive(@color: #b2b2b2, @size: 13px) {
	color: @color;
	font-size: @size;
	content: "\e673";
}
.icon-font-restore-archive(@color: #b2b2b2, @size: 13px) {
	color: @color;
	font-size: @size;
	content: "\e674";
}

.icon-font-arrow-back(@color: #a2a2a2, @size: 9px) {
	color: @color;
	font-size: @size;
	content: "\e675";
}
.icon-font-arrow-forward(@color: #a2a2a2, @size: 9px) {
	color: @color;
	font-size: @size;
	content: "\e677";
}
.icon-font-cursor-default(@color: #FFF, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e678";
}
.icon-font-live-share(@color: #828282, @size: 15px) {
	color: @color;
	font-size: @size;
	content: "\e679";
}
.icon-font-mic-on(@color: #828282, @size: 15px) {
	color: @color;
	font-size: @size;
	content: "\e67a";
}
.icon-font-mic-off(@color: #828282, @size: 15px) {
	color: @color;
	font-size: @size;
	content: "\e67b";
}
.icon-font-mic-line-on(@color: @lightBlueColor, @size: 60px) {
	color: @color;
	font-size: @size;
	content: "\e681";
}
.icon-font-finger-touch(@color: #FFF, @size: 20px) {
	color: @color;
	font-size: @size;
	content: "\e67c";
}
.icon-font-comments-multi(@color: #FFF, @size: 16px) {
	color: @color;
	font-size: @size;
	content: "\e67d";
}
.icon-font-file-list(@color: #FFF, @size: 18px) {
	color: @color;
	font-size: @size;
	content: "\e67e";
}
.icon-font-comments-outline(@color: #aeaeae, @size: 10px) {
	color: @color;
	font-size: @size;
	content: "\e67f";
}
.icon-font-download(@color: @lightBlueColor, @size: 10px) {
	color: @color;
	font-size: @size;
	content: "\e680";
}
.icon-font-uxpin-logo(@color: #f2f2f2, @size: 150px) {
	color: @color;
	font-size: @size;
	content: "\e682";
}
.icon-font-error(@color: @redColor, @size: 10px) {
	color: @color;
	font-size: @size;
	content: "\e683";
}

.icon-font-comment-notes(@color: #FFF, @size: 18px) {
	color: @color;
	font-size: @size;
	content: "\e684";
}

.icon-font-comment-mode(@color: #FFF, @size: 12px) {
	color: @color;
	font-size: @size;
	content: "\e686";
}

.icon-font-webcam(@color: #828282, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e687";
}

.icon-font-browser-line(@color: #cccccc, @size: 15px) {
	color: @color;
	font-size: @size;
	content: "\e690";
}

.icon-font-mail-line(@color: #cccccc, @size: 13px) {
	color: @color;
	font-size: @size;
	content: "\e691";
}

.icon-font-phone-line(@color: #cccccc, @size: 18px) {
	color: @color;
	font-size: @size;
	content: "\e692";
}

.icon-font-qr-code(@color: #cccccc, @size: 18px) {
	color: @color;
	font-size: @size;
	content: "\e693";
}

.icon-font-download-line(@color: #cccccc, @size: 18px) {
	color: @color;
	font-size: @size;
	content: "\e694";
}

.icon-font-hdd(@color: #b2b2b2, @size: 17px) {
	color: @color;
	font-size: @size;
	content: "\e689";
}

.icon-font-cloud-upload(@color: #3498db, @size: 12px) {
	color: @color;
	font-size: @size;
	content: "\e68b";
}
.icon-font-opacity(@color: #b8b8b8, @size: 12px) {
	color: @color;
	font-size: @size;
	content: "\e697";
}


.icon-font-ios-share(@color: #2f92ff, @size: 21px) {
	color: @color;
	font-size: @size;
	content: "\e696";
}

.icon-font-time(@color: #b8b8b8, @size: 12px) {
	color: @color;
	font-size: @size;
	content: "\e698";
}

.icon-font-visibility(@color: @lightBlueColor, @size: 12px) {
	color: @color;
	font-size: @size;
	content: "\e69a";
}

.icon-font-color-picker(@color: rgba(255,255,255,0.6), @size: 12px) {
	color: @color;
	font-size: @size;
	content: "\e69b";
}
.icon-font-pin(@color: #665d37, @size: 10px) {
	color: @color;
	font-size: @size;
	content: "\e655";
}
.icon-font-multiply(@color: #888, @size: 22px) {
	color: @color;
	font-size: @size;
	content: "\00d7";
}
.icon-font-status-progress(@color: #ffc000, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e900";
}
.icon-font-status-paused(@color: #a6a6a6, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e901";
}
.icon-font-status-approved(@color: #5fcc4d, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e902";
}
.icon-font-status-rejected(@color: #cc4e4e, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e903";
}
.icon-font-status-waiting(@color: #ffc000, @size: 14px) {
	color: @color;
	font-size: @size;
	content: "\e904";
}
.icon-font-slack(@color: #FFF, @size: 20px) {
	color: @color;
	font-size: @size;
	content: "\e905";
}

/* Raster icons sprite */
.icon-raster-sketch {
	&:before {
		content: '';
		height: 11px;
		left: -15px;
		width: 12px;
		top: 3px;
		.rasterSprite(transparent, 0, 0);
	}
}
.icon-raster-photoshop-cc {
	&:before {
		content: '';
		left: -15px;
		height: 12px;
		top: 2px;
		width: 12px;
		.rasterSprite(transparent, -20px, 0);
	}
}