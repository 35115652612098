/* CORE: mixins set */

/* border radious */
.border-radius(@radius: 4px) {
	border-radius: @radius;
	background-clip: padding-box;
}

/* uxpin icon font settings */
.uxpinIconFont(@family: 'UXPin-icons', @weight: normal) {
	text-align: center;
	font-family: @family;
	font-style: normal;
	font-weight: @weight;
	font-variant: normal;
	speak: none;
	text-transform: none;
	line-height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* better text underline */
.better-underline(@borderColor: #000, @opacity: 0.3, @moveBack: false, @classicHover: false) {
	position: relative;

	&:after {
		border-bottom: 1px solid @borderColor;
		bottom: 0;
		content: '';
		left: 0;
		opacity: @opacity;
		position: absolute;
		transition: opacity 0.1s ease-out;
		width: 100%;

		& when (@moveBack = true) {
			z-index: -1;
		}
	}

	& when (@classicHover = false) {
		&:hover {
			&:after {
				opacity: 0;
			}
		}
	}

	& when (@classicHover = true) {
		&:after {
			opacity: 0;
		}

		&:hover {
			&:after {
				opacity: @opacity;
			}
		}
	}
}

/* grow dynamic animation */
.grow-animate-tooltip(@translateX: -50%, @translateY: 0, @element: all, @time: 0.3s, @delay: 0s) {
	transition: @element @time cubic-bezier(.87,-0.81,.27,1.54) @delay;
	transform: scale(1) translate(@translateX, @translateY);
}

/* grow dynamic animation for cloud */
.grow-animate(@element: all, @time: 0.3s, @delay: 0s) {
	transition: @element @time cubic-bezier(.87,-0.81,.27,1.54) @delay;
}

/* show animate */
.show-animate(@time: 0.3s, @delay: 0s, @style: ease-out) {
	transition: opacity @time @style @delay, visibility @time @style @delay;
}

/* global show / hide animation */
.visibile-only-animate(@time: 0.1s, @delay: 0s) {
	visibility: visible;
	opacity: 1;
	transition: visibility @time ease-out @delay,
				opacity @time ease-out @delay;
}
.hidden-only-animate(@time: 0.1s, @delay: 0s) {
	visibility: hidden;
	opacity: 0;
	transition: visibility @time ease-out @delay,
				opacity @time ease-out @delay;
}
.visible-animate(@time: 0.3s, @delay: 0s) {
	opacity: 1;
	visibility: visible;
	transition: all @time ease-out @delay;
}
.hidden-animate(@time: 0.3s, @delay: 0s) {
	opacity: 0;
	visibility: hidden;
	transition: all @time ease-out @delay;
}
.visible(@pointeEvents: false) {
	opacity: 1;
	visibility: visible;

	& when (@pointeEvents = true) {
		pointer-events: auto;
	}
}
.hidden(@pointeEvents: false) {
	opacity: 0;
	visibility: hidden;

	& when (@pointeEvents = true) {
		pointer-events: none;
	}
}

/* box clearing stuff */
.clear-fix-after() {
	&:after {
		display: block;
		height: 0;
		clear: both;
		content: '';
		overflow: hidden;
	}
}

.hard-underline(@color: @mediumLightGrayColor) {
	background-image: linear-gradient(to top,
	rgba(0, 0, 0, 0),
	rgba(0, 0, 0, 0) 1px,
	@color 1px,
	@color 2px,
	rgba(0, 0, 0, 0) 2px);
}

.fake-input-style(@height: 32px, @borderColor: #c8c8c8, @radius: 2px, @shadow: true, @color: @mediumDarkerGrayColor, @hoverBg: true) {
	height: @height;
	border: 1px solid @borderColor;
	border-radius: @radius;
	color: @color;
	transition: border 0.2s ease-in;

	& when (@shadow = true) {
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
	}

	& when (@hoverBg = true) {
		transition: background-color 0.2s ease-in,
					border 0.2s ease-in;

		&:hover {
			background-color: #eaeaea;
		}
	}
}

/* spinner */
.spinner(@size: 17px; @weight: 4px; @color: #ffffff; @position: absolute; @pseudoElement: ~':after'; @duration: 0.7s) {
	&@{pseudoElement} {
		width: @size;
		height: @size;
		margin: -@size/2 0 0 -@size/2;
		border-radius: 100%;
		border: @weight solid @color;
		border-top-color: transparent !important;
		position: @position;
		top: 50%;
		left: 50%;
		z-index: 5;
		animation: loading @duration infinite linear;
		content: '';
	}

	@keyframes loading {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

.spinner-hidden(@size: 17px, @weight: 4px, @color: #ffffff) {
	.spinner(@size, @weight, @color);
	&:after {
		.hidden();
	}
}

.spinner-visible(@size: 17px, @weight: 4px, @color: #ffffff) {
	.spinner(@size, @weight, @color);
	&:after {
		.visible();
	}
}

/* background pattern for transparent colors */
.transparent-color() {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #FFF;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAJElEQVQYV2O8d/fufwYkoKSszIjMZ6SDAmT7QGx0K1EcRBsFAMKWHmVc/WmMAAAAAElFTkSuQmCC');
	.border-radius(2px);
	z-index: -1;
	left: 0;
	top: 0;
}

/* select arrow */
.select-arrow(@color: #ababab, @size: 3px) {
	&:before {
		.icon-font-down-arrow(@color, @size);
		.uxpinIconFont();
		position: absolute;
		top: 11px;
		right: 11px;
		z-index: 3;
		cursor: pointer;
		pointer-events: none;
	}
}

/* collapsed / expanded arrow */
.collapse-expand-trigger(@color: rgba(255,255,255,0.7), @size: 4px) {
	display: inline-flex;
	box-sizing: border-box;
	justify-content: center;
	overflow: hidden;
	white-space: nowrap;
	cursor: pointer;

	&.expanded {
		&:before {
			transform: rotate(0);
		}
	}
	&.collapsed {
		&:before {
			transform: rotate(-90deg);
		}
	}
	&:before {
		.icon-font-down-arrow(@color, @size);
		.uxpinIconFont();
		margin: auto;
		z-index: 3;
		cursor: pointer;
		pointer-events: none;
		transition: transform 0.2s ease;
	}
}

/* select break line */
.break-line(@color: #e7e7e7) {
	&:after {
		content: '';
		width: 1px;
		height: 14px;
		position: absolute;
		top: 5px;
		z-index: 5;
		background-color: @color;
	}
}

/* centering element using flex */
.flex-centering() {
	display: flex;
	justify-content: center;
	align-items: center;
}

/* sprite */
.mainSprite(@backgroundColor: transparent, @left: 0, @top: 0) {
	background: @backgroundColor url('/pics/sprite.svg') no-repeat;
	background-position: @left @top;
	background-size: 960px 560px;
}
.appSprite(@left: 0, @top: 0, @backgroundColor: transparent) {
	background: @backgroundColor url('/pics/sprite-app.png') no-repeat;
	background-position: @left @top;
}
.cursorSprite(@element, @left: 0, @top: 0, @svg: false) {

	// False for old editor
	& when (@svg = false) {
		cursor: url('/p/cursors/arrow-rotate-@{element}.png') @left @top, auto !important;
	}
	& when (@svg = true) {
		cursor: url('/p/cursors/arrow-rotate-@{element}.svg') @left @top, auto !important;

		.firefox-hack({
			cursor: url('/p/cursors/ff/arrow-rotate-@{element}.png') @left @top, auto !important;
		});
	}
}
.rasterSprite(@backgroundColor: transparent, @left: 0, @top: 0) {
	background: @backgroundColor url('/pics/sprite-raster.png') no-repeat;
	background-position: @left @top;
}

/* firefox only */
.firefox-hack(@rules) {
	@media screen and (min--moz-device-pixel-ratio: 0) {
		@rules();
	};
}

@desktop: ~"only screen and (min-width: 1025px)";
@tablet: ~"only screen and (min-width: 768px) and (max-width: 1024px)";
@phone: ~"only screen and (max-width: 767px)";
@retina: ~"(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";
