#login {
	@formWidth: 450px;
	@newsWidth: 500px;
	@smallScreens-newsWidth: 400px;
	@resetPasswordWidth: 300px;

	display: flex;
	height: 100%;
	background: #fff;
	color: @ds-color--gray-darken1;
	font-size: @ds-typo--fontSize-base;
	text-align: center;
	flex-flow: column;

	&-login {
		width: 100%;
    	max-width: 290px;
    	height: 40px;
    	padding: 0px 12px;
    	border: 1px solid #e6e6e6;
    	font-family: Soleil, sans-serif;
    	font-size: 14px;
	}

	.uxpin-logo {
		.uxpin-logo(@boxSize: 50px, @position: relative, @softTooltip: false, @backgroundColor: @ds-color--gray-darken10, @borderColor: @ds-color--gray-darken10, @color: #fff);

		.desktop-app& {
			pointer-events: none;
		}
	}

	.login-promo {
		display: flex;
		width: @newsWidth;
		max-height: 100vh;
		margin-left: auto;
		background-color: @ds-color--gray-darken10;
		align-items: flex-end;
		flex-direction: column;
		flex-shrink: 0;
		overflow: hidden;

		@media screen and (max-width: @smallScreensSize) {
			width: @smallScreens-newsWidth;
		}

		.heading {
			display: flex;
			width: 100%;
			padding-top: 36px;
			padding-bottom: 12px;
			align-items: center;
			flex-direction: column;
			flex-grow: 1;
			flex-shrink: 0;
			justify-content: center;
		}

		.title {
			.ds-headline-4();
			color: #fff;
			font-weight: 300;
			text-transform: none;
		}

		.ds-btn--download-app {
			margin-top: 36px;
		}

		.pic {
			@smallScreen-picSpacing: 10px;

			max-width: 456px;
			width: 100%;
			height: auto;
			margin-top: auto;
			flex-shrink: 0;
			pointer-events: none;

			@media screen and (max-width: @smallScreensSize) {
				width: ~'calc(100% - @{smallScreen-picSpacing})';
				margin-left: @smallScreen-picSpacing;
			}
		}

		.ds-icon--font__brand-apple {
			&:before {
				margin-top: -4px;
			}
		}
	}

	#login-form {
		.static-width {
			width: @formWidth;
		}

		.reset-password {
			width: @resetPasswordWidth;
		}

		&.with-google-signin {
			text-align: center;
			margin:auto;

			.static-width {
				max-width: 300px;

				.ds-input20__field{
					max-width: 320px;
					height: 50px;
				}
			}

			label {
				margin-bottom: 9px;
			}

			.separator {
				.ds-text-base(@color: @ds-color--silver-darken25);
				width: 100%;
				max-width: 450px;
				height: @ds-typo--lineHeight-base;
				margin: 17px auto 16px;
				border: none;
				position: relative;
				text-align: center;

				// horizontal line:
				&:before {
					content: '';
					width: 100%;
					border: 1px @ds-color--silver-base;
					border-style: solid none none;
					position: absolute;
					top: 50%;
					left: 0;
				}
				// separator copy:
				&:after {
					content: attr(data-text);
					display: inline-block;
					background-color: #fff;
					padding: 0 10px;
					position: relative;
				}
			}

			.google-login-button {
				font-family: Roboto, 'proxima-nova', 'Proxima Nova', sans-serif;
				font-size: @ds-typo--fontSize-textM;

				img {
					margin-right: 10px;
				}
			}

			.google-error-msg {
				display: inline-block;
				margin-top: 11px;

				&,
				a {
					color: @ds-color--red-lighten15;
					font-weight: normal;
				}
			}
		}

		.field-group {
			margin-bottom: 21px;

			&.without-margin {
				margin-bottom: 0;
			}
		}

		.form-footer {
			.ds-text-m();

			color: @ds-color--gray-lighten15;
		}

		.server-error-msg,
		.error-msg {
			display: none;
			margin-top: 9px;
			color: @ds-color--red-lighten5;

			&.is-visible {
				display: block;
			}
		}
	}

	.footer {
		@footerSpacing: 30px;
		@logoSpacing: 20px;

		display: flex;
		position: absolute;
		right: @footerSpacing;
		bottom: @footerSpacing;
		align-items: center;
		flex-direction: row;
		justify-content: flex-end;

		.dot-separator {
			@dotSize: 4px;

			width: @dotSize;
			height: @dotSize;
			margin-right: @dotSize * 2;
			margin-left: @dotSize * 2;
			border-radius: 50%;
			background-color: @ds-color--silver-darken25;
		}

		.footer-item {
			margin-bottom: 0;
		}

		.uxpin-logo {
			margin-left: @logoSpacing;
		}
	}

	#login-notification {
		width: @formWidth;
	}

	#login-form,
	#login-notification {
		text-align: left;

		.desktop-app& {
			-webkit-app-region: no-drag;
		}

		.form-lead {
			.title {
				.ds-headline-2(@color: @ds-color--gray-darken5);
				margin-bottom: 34px;
				text-transform: none;
			}

			p {
				margin-bottom: 34px;
				color: #909090;
				line-height: 18px;

				&.global-error {
					color: @newRedColor;
				}
				&.text {
					.ds-text-base(@color: @ds-color--gray-lighten5);
				}
				&.warning {
					margin-bottom: 22px;
					color: @newRedColor;
					font-size: 18px;
					font-weight: normal;
					line-height: 30px;
				}
			}
		}

		.btns-group {
			.light-blue-style {
				background-color: @ds-color--blue-base;

				&:hover {
					background-color: lighten(@ds-color--blue-base, 10%);
				}
			}
		}

		footer {
			padding: 9px 0 0 0;

			.clear-fix-after();

			.field-group {
				padding: 4px 29px 4px 0;
				border-right: 1px solid #e7e7e7;
				float: left;

				#loginform_button1 {
					&:not(.working) {
						&:hover {
							background-color: lighten(@ds-color--blue-base, 10%);
						}
					}

					&.working {
						&:after {
							margin-top: -11px;
							border-color: #fff;
						}
					}
				}
			}
			.other-links {
				padding: 12px 0 0 29px;
				float: left;

				p {
					display: block;
					margin-bottom: 10px;
					position: relative;
					z-index: 0;
					color: @ds-color--gray-lighten15;
					line-height: 12px;

					a {
						color: @ds-color--gray-lighten25;
						font-weight: normal;
						text-decoration: none;

						&:after {
							bottom: 1px;
						}
					}
				}
			}
		}
		&.password-reminder {
			display: flex;
			width: 100%;
			margin-right: auto;
			margin-left: auto;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			transform: translateY(75%);

			footer {
				.other-links {
					padding-top: 24px;
				}
			}

			&.email-sent {
				header {
					p {
						display: none;
					}
				}

				.success-layer {
					display: flex;
					align-items: center;
					flex-direction: column;

					p {
						margin-bottom: 30px;
					}

					.btn-flat {
						display: block;
						width: 80px;
						background-color: @ds-color--blue-base;
						line-height: 50px;
						text-decoration: none;

						&:hover {
							background-color: lighten(@ds-color--blue-base, 10%);
						}
					}
				}

				fieldset {
					display: none;
				}
			}
		}
		.global-error {
			color: @ds-color--red-lighten15;
		}
	}

	&.desktop-app {
		-webkit-app-region: drag;

		& * {
			-webkit-app-region: no-drag;
		}

		#login-form {
			.form-lead {
				.sign-up-notice {
					.ds-text-m();
					margin-bottom: 44px;
					color: @ds-color--gray-lighten30;
					text-align: center;
				}

				.signup-link {
					&:after {
						border-color: @ds-color--gray-lighten30;
					}
				}

				.lonely-dot {
					&.invisible {
						opacity: 0;
					}
				}
			}
		}
	}

  .social-login__header-notification {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .social-login__message--success {
    text-align: center;
  }

  .social-login__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 1.25rem;
  }

  .social-login__header-right {
    display: flex;
    gap: 0.5em;
  }

  .social-login__header-link {
    font-family: Soleil, sans-serif;
    font-size: 14px;
    color: #6666ff;
    font-weight: 500;
  }

  .social-login__header-separator {
    color: #acacac;
    font-size: 14px;
  }

  .social-login__form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
  }

  .social-login {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  .social-login__title {
    font-family: Soleil, sans-serif;
    font-size: 36px;
    color: #121212;
    text-align: center;
    text-transform: none;
    font-weight: 500;
    margin-bottom: 40px;
  }

  .social-login__form-wrapper {
    padding: 1.25rem;
  }

  form .social-login__label {
    font-family: Soleil, sans-serif;
    font-size: 14px;
    color: #121212;
    display: block;
    margin-bottom: 4px;
  }

  .social-login__input {
    width: 100%;
    max-width: 360px;
    height: 40px;
    padding: 0px 12px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    font-family: Soleil, sans-serif;
    font-size: 14px;
    color: #121212;
    margin-bottom: 12px;
  }

  .social-login__input-group--password {
    position: relative;
  }

  .toggle-password {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 21px;
    transform: translateY(-75%);
    cursor: pointer;
  }

  .social-login__btn {
    width: 100%;
    max-width: 360px;
    font-weight: 600;
    height: 48px;
    padding: 14px 1px 14px 0px;
    border: 2px solid #121212;
    border-radius: 4px;
    background-color: #ccccff;
    font-family: Soleil, sans-serif;
    font-size: 16px;
    color: #121212;
    text-align: center;
  }

  .social-login__btn:hover {
    background-color: #f3f3f3;
  }

  .social-login__separator {
    position: relative;
    text-align: center;
    border-top: 1px solid #e6e6e6;
    margin: 30px 0;
  }

  .social-login__separator span {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    padding: 0 10px;
    font-family: Soleil, sans-serif;
    font-size: 14px;
    color: #acacac;
  }

  .social-login__fieldset-btns {
    display: flex;
    gap: 0.5em;
  }

  .social-login__btn--google,
  .social-login__btn--github,
  .social-login__btn--sso {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    width: 100%;
    max-width: 114px;
    height: 48px;
    padding: 0.5em 1.1em;
    border: 2px solid #121212;
    border-radius: 4px;
    font-family: Soleil, sans-serif;
    font-size: 16px;
    color: #121212;
    text-decoration: none;
  }

  .social-login__btn--google:hover,
  .social-login__btn--github:hover,
  .social-login__btn--sso:hover {
    background-color: #f3f3f3;
  }

  .social-login__error-msg--google,
  .social-login__error-msg--github,
  .social-login__error-msg--server {
    margin-top: 1rem;
    text-align: center;
  }

  .social-login__btn--sso {
    width: 114px;
  }

  .social-login__footer {
    margin-top: 36px;
    text-align: center;
  }

  .social-login__footer-text {
    font-family: Soleil, sans-serif;
    font-size: 14px;
    color: #121212;
  }

  .social-login__footer-link {
    font-family: Soleil, sans-serif;
    font-size: 14px;
    color: #6666ff;
    font-weight: 500;
  }

  .social-login__error-msg {
    color: red;
  }
}

#browser-bar {
	width: 100%;
	padding: 10px 10px 12px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
	background: @yellowColor;
	color: @mediumGrayBlueColor;
}

::selection {
	background: @ds-color--blue-base;
}
