// Uxpin icon font settings
.uxpinIconFont(@family: 'icons-set-betty', @weight: normal) {
	text-align: center;
	font-family: @family;
	font-style: normal;
	font-weight: @weight;
	font-variant: normal;
	speak: none;
	text-transform: none;
	line-height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.visible(@pointeEvents: false) {
	opacity: 1;
	visibility: visible;

	& when (@pointeEvents = true) {
		pointer-events: auto;
	}
}

.hidden(@pointeEvents: false) {
	opacity: 0;
	visibility: hidden;

	& when (@pointeEvents = true) {
		pointer-events: none;
	}
}

// Box clearing stuff
.clearfix-after() {
	&:after {
		content: '';
		display: block;
		height: 0;
		clear: both;
		overflow: hidden;
	}
}

// Border radius outside background
.border-radius-outside(@radius: 4px) {
	border-radius: @radius;
	background-clip: padding-box;
}

// Loading spinner
.spinner(@size: 17px; @weight: 4px; @color: #ffffff; @position: absolute; @pseudoElement: ~':after'; @duration: 0.5s) {
	&@{pseudoElement} {
		content: '';
		width: @size;
		height: @size;
		margin: -@size/2 0 0 -@size/2;
		border-radius: 100%;
		border: @weight solid @color;
		border-top-color: transparent !important;
		position: @position;
		top: 50%;
		left: 50%;
		z-index: 5;
		animation: loading @duration infinite linear;
	}

	@keyframes loading {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}
.spinner-hidden(@size: 17px, @weight: 4px, @color: #ffffff) {
	.spinner(@size, @weight, @color);

	&:after {
		.hidden();
	}
}
.spinner-visible(@size: 17px, @weight: 4px, @color: #ffffff) {
	.spinner(@size, @weight, @color);

	&:after {
		.visible();
	}
}

.fake-input-style(@height: 32px, @borderColor: @ds-color--silver-darken15, @radius: 2px, @shadow: true, @color: @mediumDarkerGrayColor, @hoverBg: true) {
	height: @height;
	border: 1px solid @borderColor;
	border-radius: @radius;
	color: @color;
	transition: border 0.2s ease-in;

	& when (@shadow = true) {
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
	}

	& when (@hoverBg = true) {
		transition: background-color 0.2s ease-in,
					border 0.2s ease-in;

		&:hover {
			background-color: @ds-color--silver-darken5;
		}
	}
}

.fake-input-chevron-position() {
	padding-right: 15px;
	position: relative;
	cursor: pointer;

	// Chevron icon
	&:before {
		position: absolute;
		top: 9px;
		right: 0;
	}
}

.fake-light-input-style(@height: @ds-elementHeight--s, @borderColor: @ds-input--borderColor-default, @color: @ds-input--fontColor-default, @withChevron: false) {
	height: @height;
	color: @color !important; // Delete importants after removing CodeSync nots
	line-height: @height !important;
	box-shadow: inset 0 -1px 0 0 @borderColor;

	.dark-version & {
		color: @ds-color--silver-darken10 !important;
		box-shadow: inset 0 -1px 0 0 @ds-color--gray-lighten5;
	}

	& when (@withChevron = true) {
		.fake-input-chevron-position();
	}
}

// Animations
.show-animate(@time: 0.3s, @delay: 0s, @style: ease-out) {
	transition: opacity @time @style @delay, visibility @time @style @delay;
}
.visible-animate(@time: 0.3s, @delay: 0s) {
	opacity: 1;
	visibility: visible;
	transition: all @time ease-out @delay;
}
.hidden-animate(@time: 0.3s, @delay: 0s) {
	opacity: 0;
	visibility: hidden;
	transition: all @time ease-out @delay;
}

// Custom text underline
.underline(@borderColor: #000, @opacity: 0.3, @moveBack: false, @classicHover: false) {
	position: relative;

	&:after {
		content: '';
		border-bottom: 1px solid @borderColor;
		bottom: 0;
		left: 0;
		opacity: @opacity;
		position: absolute;
		transition: opacity 0.1s ease-out;
		width: 100%;

		& when (@moveBack = true) {
			z-index: -1;
		}
	}

	& when (@classicHover = false) {
		&:hover {
			&:after {
				opacity: 0;
			}
		}
	}

	& when (@classicHover = true) {
		&:after {
			opacity: 0;
		}

		&:hover {
			&:after {
				opacity: @opacity;
			}
		}
	}
}

// Background pattern for transparent colors
.transparent-color() {
	.border-radius-outside(2px);
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: -1;
	background-color: #FFF;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAJElEQVQYV2O8d/fufwYkoKSszIjMZ6SDAmT7QGx0K1EcRBsFAMKWHmVc/WmMAAAAAElFTkSuQmCC');
}

// Select break line
.break-line-after(@color: @ds-color--silver-darken5) {
	&:after {
		content: '';
		width: 1px;
		height: 14px;
		position: absolute;
		top: 5px;
		z-index: 5;
		background-color: @color;
	}
}

// Element underline with transition effect
.soft-hover(@borderColor: @ds-color--blue-base, @classicHover: true, @borderStyle: solid, @lineHeight: 14px, @borderOpacityPercentage: 50%, @duration: 0.2s) {
	border-bottom: 1px @borderStyle;
	text-decoration: none !important;
	line-height: @lineHeight; // Should match font size
	transition: border-color @duration ease-in;

	// Underline fades in on hover
	// Default link behaviour but with transition
	& when (@classicHover = true) {
		border-bottom-color: transparent;

		&:hover {
			border-color: fade(@borderColor, @borderOpacityPercentage) !important;
		}
	}

	// Underline fades out on hover
	& when (@classicHover = false) {
		border-bottom-color: fade(@borderColor, @borderOpacityPercentage);

		&:hover {
			border-bottom-color: transparent !important;
		}
	}
}

// Alter default tooltip behavior - add transition & show after delay
.soft-tooltip(@delay: 0.2s, @display: block) {
	.tooltip {
		display: @display;
		height: 0;
		text-indent: 0;
		.hidden();
		.show-animate(0s, 0s, ease-in);
	}

	.btn-label {
		.with-labels & {
			transition: opacity 0.2s ease-out 0s;

			&:before {
				transition: color 0.1s ease-out 0s;
			}
		}
	}

	&@{tooltipParentAction} {
		&:not(.active) {
			.tooltip {
				height: auto;
				z-index: 10;
				.visible();
				transition-delay: @delay;
				transition-duration: 0.2s;
			}

		    //Link hiding labels with showing tooltips
			.btn-label {
				.with-labels & {
					opacity: 0;
					transition: opacity 0.2s ease-out @delay;
				}
			}
		}

	}
}

// Disable any element, prevent clicks and optionally blue blinks on mouse select
.disabled(@opacity: 0.5, @userSelect: true) {
	opacity: @opacity;
	pointer-events: none;

	& when (@userSelect = true) { // TODO Remove in future, when user-select:none will be global
		user-select: none;
	}
}

// Add 3 dots at the end of a single-line text when is longer than set width
// Setting @maxWidth: initial won't generate max-width property
.text-ellipsis(@maxWidth: 100%, @whiteSpace: true) {
	text-overflow: ellipsis;
	overflow: hidden;

	& when (@whiteSpace = true) {
		white-space: nowrap;
	}

	& when (isnumber(@maxWidth)) {
		max-width: @maxWidth;
	}
}

// Project specific mixins below
// Some common components needs them
// And thus they have to be accessed globally


// Project: Editor-30
// Replacing text in hulk-select list and input with visual aid (thickness of border)
// Included in General-30/Element-HulkSelect.less
.visual-border() {
	position: relative;

	&:after {
		content: '';
		width: auto;
		border-bottom-width: 2px;
		position: absolute;
		top: 50%;
		left: @listLeftPadding;
		transform: translateY(-50%);
	}

	&.solid,
	&[data-value="solid"] {
		&:after {
			border-bottom-style: solid;
		}
	}
	&.dotted,
	&[data-value="dotted"] {
		&:after {
			border-bottom-style: dotted;
		}
	}
	&.dashed,
	&[data-value="dashed"] {
		&:after {
			border-bottom-style: dashed;
		}
	}
}

// Project: Dashboard-30
// One line text with underline and ellipsis dots
.one-line-underline-ellipsis(@maxWidth: 100%, @underlineColor: @mediumLightGrayColor) {
	.text-ellipsis(@maxWidth: @maxWidth);
	display: inline-block;
	position: relative;
	z-index: 0;

	&:after {
		content: '';
		width: 100%;
		border-bottom: 1px solid @underlineColor;
		position: absolute;
		bottom: 1px;
		left: 0;
		z-index: -1;
	}
}

.input-placeholder(@color: @ds-color--silver-darken10, @fontSize: 14px, @fontStyle: normal, @textIndent: 0) {
	&::-webkit-input-placeholder {
		color: @color;
		font-size: @fontSize;
		font-style: @fontStyle;
		text-indent: @textIndent;
	}
	&:-moz-placeholder {
		color: @color;
		font-size: @fontSize;
		font-style: @fontStyle;
		text-indent: @textIndent;
	}
	&::-moz-placeholder {
		color: @color;
		font-size: @fontSize;
		font-style: @fontStyle;
		text-indent: @textIndent;
	}
	&:-ms-input-placeholder {
		color: @color;
		font-size: @fontSize;
		font-style: @fontStyle;
		text-indent: @textIndent;
	}
}

.flex-centering() { // centering element using flex
	display: flex;
	justify-content: center;
	align-items: center;
}

.uxpin-logo(@boxSize: 40px, @position: relative, @borderColor: @ds-color--silver-darken10, @softTooltip: true, @color: fade(rgb(183, 28, 28), 40%), @backgroundColor: transparent) {
	width: @boxSize; // must be divisible by 4 as the font-size is 4 times smaller than boxSize
	height: @boxSize;
	position: @position;
	text-decoration: none;

	& when (@softTooltip = true) {
		.soft-tooltip();
	}

	&:before {
		.ds-uxpin-logo(@iconColor: @color, @size: @boxSize, @iconSize: @boxSize / 4, @borderColor: @borderColor, @backgroundColor: @backgroundColor);
		.flex-centering();
	}
}

.multiline-underline(@color: fade(@ds-color--blue-base, 30%), @positionY: 12px, @size: 2px, @hover: true) {
	background-image: linear-gradient(to bottom, fade(#fff, 5%) 50%, @color 75%);
	background-position: 0 @positionY;
	background-repeat: repeat-x;
	background-size: @size @size;
	overflow: hidden;

	.firefox & {
		background-position: 0 (@positionY + 1px);
	}

	& when (@hover = true) {
		&:hover {
			background-size: 0;
		}
	}
}

.css-triangle(@direction: up, @width: 10px, @height: 10px, @color: @ds-color--silver-darken30) {
	content: '';
	display: block;
	height: @height; // Fix for pseudo element rounded size and small gap

	// Up / Bottom
	& when (@direction = up) {
		border-bottom: @height solid @color;
	}
	& when (@direction = down) {
		border-top: @height solid @color;
	}
	& when (@direction = up), (@direction = down)  {
		margin-top: -@height / 2;
		margin-left: -@width / 2;
		border-left: (@width / 2) solid transparent;
		border-right: (@width / 2) solid transparent;
	}

	// Left / Right
	& when (@direction = left) {
		border-right: @height solid @color;
	}
	& when (@direction = right) {
		border-left: @height solid @color;
	}
	& when (@direction = left), (@direction = right)  {
		margin-top: -@width / 2;
		margin-left: -@height / 2;
		border-top: (@width / 2) solid transparent;
		border-bottom: (@width / 2) solid transparent;
	}
}

.cursor-sprite(@element, @left: 0, @top: 0) {
	cursor: url('/p/cursors/editor-30/arrow-rotate-@{element}.png') @left @top, auto !important;

	@media screen and @doublePixelRatio {
		cursor: -webkit-image-set(url('/p/cursors/editor-30/arrow-rotate-@{element}-@2x.png') 2x) @left @top, auto !important;
	}
}

.annotate-cursor(@mac: false) {
	& when (@mac = false) {
		cursor: url('/p/cursors/editor-30/annotate.png') 20 20, auto !important;

		@media screen and @doublePixelRatio {
			cursor: -webkit-image-set(url('/p/cursors/editor-30/annotate-@2x.png') 2x) 20 20, auto !important;
		}
	}
	& when (@mac = true) {
		cursor: url('/p/cursors/editor-30/annotate-mac.png') 20 20, auto !important;

		@media screen and @doublePixelRatio {
			cursor: -webkit-image-set(url('/p/cursors/editor-30/annotate-mac-@2x.png') 2x) 20 20, auto !important;
		}
	}
}

.remove-cursor() {
	cursor: url('/p/cursors/editor-30/trash.png') 3 3, default !important;

	@media screen and @doublePixelRatio {
		cursor: -webkit-image-set(url('/p/cursors/editor-30/trash-@2x.png') 2x) 3 3, default !important;
	}
}

.svg-point-remove-cursor() {
	cursor: url('/p/cursors/editor-30/svg-point-remove.png'), default !important;

	@media screen and @doublePixelRatio {
		cursor: -webkit-image-set(url('/p/cursors/editor-30/svg-point-remove-@2x.png') 2x), default !important;
	}
}

.svg-point-add-cursor() {
	cursor: url('/p/cursors/editor-30/svg-point-add.png'), default !important;

	@media screen and @doublePixelRatio {
		cursor: -webkit-image-set(url('/p/cursors/editor-30/svg-point-add-@2x.png') 2x), default !important;
	}
}

.svg-point-draw-custom-cursor(@icon, @cursorCoordX: 7, @cursorCoordY: 15) {
	cursor: url('/p/cursors/editor-30/cursor-@{icon}.png') @cursorCoordX @cursorCoordY, auto !important;

	@media screen and @doublePixelRatio {
		cursor: -webkit-image-set(url('/p/cursors/editor-30/cursor-@{icon}-@2x.png') 2x) @cursorCoordX @cursorCoordY, auto !important;
	}
}



.avatar-fix(@rules) {
	&:not([src*="gravatar.com"]) {
		&[src$="transparent.png"],
		&[src$="default_avatar.png"],
		&[src$="avatars/default.png"] {
			.hidden(); // Hide stupid avatar with gray guy
			@rules();
		}
	}
}

.checker-pattern(@color: @ds-color--silver-darken10, @bgColor: #fff, @size: 4px, @isBase64: false) {
	background-color: @bgColor;

	& when (@isBase64 = false) {
		background-image:
			linear-gradient(45deg, @color 25%, transparent 25%, transparent 75%, @color 75%, @color 100%),
			linear-gradient(45deg, @color 25%, transparent 25%, transparent 75%, @color 75%, @color 100%);
	}

	& when (isnumber(@size)) {
		background-size: @size * 2 @size * 2;
		background-position: 0 0, @size @size;
	}

	& when (@isBase64 = true) {
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAAXNSR0IArs4c6QAAACpJREFUGBlj/Pz5838GJMDLy8uIxGVgQuZgY1OuAMU+kBXobqLcCoImAADQwQfnQm3iRAAAAABJRU5ErkJggg==');
		background-size: 8px 8px;
	}
}

.transparency-pattern(@pseudoElement: ~':after', @borderRadius: inherit, @opacity: 1, @zIndex: -1, @color: @ds-color--silver-darken10, @bgColor: #fff, @size: 4px, @isBase64: false, @isDark: false) {
	&@{pseudoElement} {
		opacity: @opacity;
		content: '';
		border-radius: @borderRadius;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: @zIndex;

		& when (@isDark = false) {
			.checker-pattern(@color: @color, @bgColor: @bgColor, @size: @size, @isBase64: @isBase64);
		}

		& when (@isDark = true) {
			background-color: @ds-color--silver-darken10;
		}
	}
}

// Draggable elements (Editor: Sitemap & Layers)
// Indicates drop target with dashed outline
.drop-target-dash-after(@borderColor: #fff, @transitionDuration: 0.3s) {
	&:after {
		content: '';
		opacity: 0;
		border: 1px dashed @borderColor;
		border-radius: 2px;
		position: absolute;
		top: 2px;
		right: 2px;
		bottom: 2px;
		left: 2px;
		background: @ds-color--blue-base;
		pointer-events: none;
		transition: opacity @transitionDuration ease-in;
	}
}

.position-fill(@type: absolute, @value: 0) {
	position: @type;
	top: @value;
	right: @value;
	bottom: @value;
	left: @value;
}

.divider-dot(@pseudoElement: ~':before', @size: 4px, @position: absolute, @top: 0, @left: 0, @color: @ds-color--silver-darken10) {
	position: relative;

	&@{pseudoElement} {
		content: '';
		display: block;
		width: @size;
		height: @size;
		border-radius: 50%;
		position: @position;
		top: @top;
		left: @left;
		background: @color;

		.firefox & {
			top: @top + 1;
		}
	}
}

.hidden-shadow(@pseudoElement: ~':before', @type: absolute, @value: 0, @spread: 0 10px 40px, @color: fade(#000, 10%), @colorDark: fade(#000, 75%), @forceVisible: false) {
	position: relative;

	&@{pseudoElement} {
		.position-fill(@type, @value);
		content: '';
		box-shadow: @spread @color;

		& when (@forceVisible = false) {
			.hidden();
		}

		.dark-version & {
			box-shadow: @spread @colorDark;
		}
	}
}

.cross-sign(@position: absolute, @zIndex: false, @size: 34px, @stroke: 2px, @roundness: 2px, @top: ~'calc(50% - 37px)', @left: 50%, @color: @ds-color--silver-darken5) {
	&:before,
	&:after {
		content: '';
		display: block;
		width: @stroke;
		height: @size;
		border-radius: @roundness;
		position: @position;
		top: @top;
		left: @left;
		background-color: @color;
		pointer-events: none;

		& when (isnumber(@zIndex)) {
			z-index: @zIndex;
		}
	}
	&:before {
		transform: translateX(-50%) rotate(-45deg);
	}
	&:after {
		transform: translateX(-50%) rotate(45deg);
	}
}

.triangleOnBottom(@triangleColor: @colorTransparent) {
	border-top-color: @triangleColor;
	border-bottom-color: transparent;
	top: auto;
	bottom: -6px;
}

.download-overlay-pseudo(@isStyleguide: true, @background: @ds-color--blue-base, @zIndex: 1) {
	&:before {
		.ds-iconFont(@glyph: @ds-icon--files-download-cloud, @color: #fff, @size: 22px, @fontEmbed: true);
		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: @zIndex;
		transform: translate(-50%, -50%);
		pointer-events: none;
	}

	& when (@isStyleguide = true) {
		// Border helper (Styleguide only)
		&:after {
			.position-fill();
			content: '';
			display: block;
			border: 1px solid #e9e9e9;
			border-radius: 2px;
			pointer-events: none;
		}
	}

	&:not(.loading) {
		&:hover {
			box-shadow: 0 10px 60px fade(#000, 10%);

			// Download icon
			&:before {
				display: block;
			}

			& when (@isStyleguide = true) {
				// Border helper (Styleguide only)
				&:after {
					display: none;
				}

				figure {
					box-shadow: 0 1px 0 @ds-color--silver-base;
				}
			}

			figure {
				// Hover overlay
				&:after {
					opacity: 0.95;
					display: block;
				}
			}
		}

		figure {
			// Hover overlay
			&:after {
				.position-fill();
				content: '';
				display: none;
				border-radius: 2px;
				z-index: @zIndex - 1;
				background-color: @background;
				pointer-events: none;
			}
		}
	}
}

.dragging-overlay-pseudo(@borderColor: #d1d1d1, @fontSize: 16px, @bg: fade(#fff, 85%), @top: 5px + @mainPadding, @right: 0, @left: 0, @bottom: @mainPadding, @zIndex: 1, @responsive: true) {
	// White overlay on dragging target
	&:before {
		content: '';
		position: absolute;
		z-index: @zIndex;
		background: @bg;
	}
	// Dashed border
	&:after {
		content: '';
		border: 1px dashed @borderColor;
		border-radius: 2px;
		position: absolute;
		top: @top;
		right: @right;
		bottom: @bottom;
		left: @left;
		z-index: @zIndex;
		background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IldhcnN0d2FfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAxMiAxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIgMTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojRjFGMkYyO308L3N0eWxlPjxnPjxsaW5lIGNsYXNzPSJzdDAiIHgxPSItMC45IiB5MT0iMTIuOSIgeDI9IjEyLjkiIHkyPSItMC45Ii8+PHJlY3QgeD0iLTMuNyIgeT0iNS41IiB0cmFuc2Zvcm09Im1hdHJpeCgwLjcwNzEgLTAuNzA3MSAwLjcwNzEgMC43MDcxIC0yLjQ4NTQgNi4wMDAyKSIgY2xhc3M9InN0MCIgd2lkdGg9IjE5LjQiIGhlaWdodD0iMSIvPjwvZz48L3N2Zz4=);
		background-repeat: repeat;
		background-size: 12px 12px;

		& when (@responsive = true) {
			@media screen and (max-width: @smallScreensSize) {
				top: 5px + @smallScreens-mainPadding;
				bottom: @smallScreens-mainPadding;
			}
		}
	}

	.dragging-target-name {
		position: absolute;
		z-index: @zIndex + 1;
		color: @ds-color--gray-lighten20;
		font-size: @fontSize;

		.dark-version & {
			color: @ds-color--silver-darken20;
		}

		strong {
			color: @ds-color--gray-lighten1;
			font-weight: normal;

			.dark-version & {
				color: #fff;
			}
		}
	}
}

.dragging-ghost-multiple-after(@color: @ds-color--silver-darken5) {
	display: flex;
	align-items: center;
	justify-content: center;

	&:after {
		content: '';
		border: 1px solid @color;
		border-radius: 2px;
		position: absolute;
		top: 4px;
		right: -6px;
		bottom: -6px;
		left: 4px;
		z-index: -1;
		background-color: #fff;
	}
}

.calc-columns-width(@columns, @gap, @grid: true) {
	@columnWidth: round(100% / @columns, 3);
	@gapCount: @columns - 1;
	@gapOffset: round(@gapCount * @gap / @columns, 3);
	@width: ~'calc(@{columnWidth} - @{gapOffset})';

	& when (@grid = true) {
		grid-template-columns: repeat(@columns, @width);
	}

	& when (@grid = false) {
		width: @width;
	}
}

.flex-centering() {
	display: flex;
	justify-content: center;
	align-items: center;
}

.pulsing-border-pseudo(@pseudoElement: ~':after', @position: absolute, @zIndex: @maxZindex, @color: #000, @opacity: 10%, @time: 1.2s, @withTrigger: false, @class: ~'.edit-mode', @withAnimation: true) {
	&@{pseudoElement} {
		.position-fill(@type: @position);
		content: '';
		display: none;
		height: 100%;
		border: 5px solid fade(@color, @opacity);
		background: none;
		z-index: @zIndex;
		pointer-events: none;
		transition: opacity 0.2s ease-in-out;

		& when (@withAnimation = true) {
			animation: border-pulse-mode @time ease-out infinite normal;
		}
	}

	& when (@withTrigger = true) {
		@{class}& {
			&@{pseudoElement} {
				display: block;
			}
		}
	}
}

.text-clamp(@lines: 3) {
	display: -webkit-box;
	-webkit-line-clamp: @lines;
	/*! autoprefixer: off */
	-webkit-box-orient: vertical;
	/*! autoprefixer: on */
	overflow: hidden;
}

.keep-ratio-pseudo(@pseudoElement: ~':after') {
	&@{pseudoElement} {
		content: '';
		display: block;
		width: 100%;
		padding-top: 100%;
	}
}

/* horizontal lines on both sides of the text */
.lines-on-side(@color: @ds-color--silver-darken1, @colorDark: @ds-color--gray-darken1, @width: 150px) {
	position: relative;

	&:before,
	&:after {
		content: '';
		display: block;
		width: @width;
		height: 1px;
		position: absolute;
		top: 50%;
		background: @color;
	}
	&:before {
		margin-right: 10px;
		right: 100%;
	}
	&:after {
		margin-left: 10px;
		left: 100%;
	}

	.dark-version & {
		&:before,
		&:after {
			background: @colorDark;
		}
	}
}

.compense-scrollbars-width(@offset: 0) {
	width: ~'calc(100% - @{editorScrollBarSize} - @{offset})';

	.firefox & {
		width: ~'calc(100% - @{windowsScrollPaneSize} - @{offset})';

		.mac& {
			width: ~'calc(100% - @{macScrollPaneSize} - @{offset})';
		}
	}
}

// Apply unified styles for all measurments/distances/smart-guides
// values on canvas to avoid differences between them
.measurment-value-style(@display: inline-block, @bgColor: @distancesColor, @position: '') {
	display: @display;
	padding: 4px 5px;
	border-radius: 2px;
	background-color: @bgColor;
	color: #fff;
	font-family: @ds-typo--fontFamily-code;
	font-size: @ds-typo--fontSize-textXS;
	font-weight: bold;
	line-height: 100%;

	& when not (@position = '') {
		@offset: 50%;
		position: @position;

		.horizontal& {
			left: @offset;
			transform: translateX(-@offset);
		}
		.vertical& {
			top: @offset;
			transform: translateY(-@offset);
		}
	}
}


.fix-blurry-icons(@value) {
	transform: translateX(unit(@value, px));
}

@keyframes pinPulse {
	0% {
		opacity: 0;
		transform:scale(1);
	}
	50% {
		opacity: 0.3;
	}
	100% {
		opacity: 0;
		transform: scale(1.5);
	}
}

@keyframes border-pulse-mode {
	0%,
	100% {
		border: 5px solid rgba(0, 0, 0, 0.05);
	}
	50% {
		border: 5px solid rgba(0, 0, 0, 0.15);
	}
}

@keyframes blink-opacity {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes max-width {
	100% {
		max-width: 100%;
	}
}

@keyframes animate-hidden {
	// Simply shows hidden element after defined time. Usage:
	// > .hidden();
	// >  animation: animate-hidden 0.3s ease-out 0s 1 forwards;

	100% {
		.visible();
	}
}

@keyframes animate-visible {
	// As above but for visible el.

	100% {
		.hidden();
	}
}

/* some merge libraries overrides global styles and our UI is broken - it's workaround */
.merge-hack() {
	font-family: @ds-typo--fontFamily-base;
	font-size: @ds-typo--fontSize-base;
	line-height: @ds-typo--lineHeight-base;
	color: @ds-text--color-base;
	.merge-hack-box-sizing();
}

.merge-hack-box-sizing() {
	box-sizing: border-box;
	*, *:after, *:before {
		box-sizing: border-box;
	}
}
// ------------------------------------------------------------------------------------ */
